import {Link, usePage} from '@inertiajs/react';
import {truncate} from 'lodash';

export default function ({
    element,
    width = 14,
}: {
    element: any;
    width?: number;
}) {
    const {currentLang}: any = usePage().props;
    return (
        <Link
            href={route(`${currentLang}.frontend.user.show`, element.id)}
            className="flex flex-col justify-center items-center truncate hover:bg-gray-100 rounded-xl p-4"
        >
            <img
                loading="lazy"
                src={element.thumb}
                alt={element.name}
                className={`w-${width} h-${width} rounded-full drop-shadow-sm border border-gray-100`}
            />
            <div className="truncate py-3 text-xxs">
                {truncate(`${element.title.name} ${element.name}`, {
                    length: 30,
                })}
            </div>
        </Link>
    );
}
